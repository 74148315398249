import { useSearchParams } from "@remix-run/react";

export const useSort = ({ defaultSortParam }: { defaultSortParam: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSortChange = (sortParam: string) => {
    setSearchParams(
      (searchParams) => {
        searchParams.set("sort-by", sortParam);
        return searchParams;
      },
      { preventScrollReset: true, replace: true }
    );
  };

  return {
    currentSortParam: searchParams.get("sort-by") || defaultSortParam,
    handleSortChange,
  };
};
